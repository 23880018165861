<template>
  <div class="mx-5">
    <b-row class="m-0 my-1" style="align-items:center;" align-h="between" >
      <b-col cols="4" style="display:flex; align-items:center;">
        <b-link class="brand-logo">
            <b-img
              v-if="gettingA"
              :src="logoAzienda ? logoUrl : appLogoImage"
              alt="logo"
              style="height:25px;"
            />

        </b-link>
        <!-- <b-card-title
          class="m-0 ml-1"
        >
         benvenuto su WB OstiSistemi
        </b-card-title> -->
      </b-col>
      <b-col cols="2" class="p-0">
        <dark-Toggler style="width:100%;" class="d-lg-block" />
      </b-col>
    </b-row>

    <div v-if="choosing">
      <b-card v-if="azienda" :title="azienda.ragioneSociale">
        <b-row align-h="around" class="my-2 text-center">
          <h3>
            Puoi inviare una nuova segnalazione (anonima o firmata) e consultare le tue segnalazione attive
          </h3>
        </b-row>
        <b-row class="mt-2 text-center" align-h="center">
          <b-col cols="12" class="align-items-center">
            <b-button
              class="mx-1"
              variant="outline-success"
              @click="choosing=false"
            >
              Invia segnalazione
            </b-button>
          </b-col>
          <b-col cols="12" class="my-2 align-items-center">
            <p class="m-0">
              <small>Oppure</small>
            </p>
          </b-col>
          <b-col cols="6" class="align-items-center">
            <p>
              Consulta e rispondi ad una segnalazione di cui conosci ID e Password <small>(se impostata)</small>
            </p>
            <b-input-group>
               <b-form-input v-model="id" placeholder="Identificativo" />
            </b-input-group>
            <b-input-group class="mt-1">
               <b-form-input v-model="password" placeholder="Password (opzionale)" />
            </b-input-group>
            <b-button
              class="m-1"
              variant="outline-warning"
              @click="getSegnalazione()"
            >
              Consulta segnalazione
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  <!-- CREATE or Update -->
    <div v-else>
      <b-card class="text-center">
        <b-row align-h="between">
          <b-button
            class="mx-1"
            text="mx-1"
            variant="outline-warning"
            @click="resetField()"
          >
           Indietro
          </b-button>
          <b-card-title class="m-1">
            <b-card-text class="text-secondary">
              In fase di creazione è possibile impostare anche un secondo livello di sicurezza impostando una password che sarà necessaria per poterla consultare in un secondo momento.
            </b-card-text>
          </b-card-title>
        </b-row>
      </b-card>
      <b-card
        title="Inserisci nuova Segnalazione"
      >
        <b-input-group>
           <b-form-input v-model="title" placeholder="Titolo" />
        </b-input-group>
        <b-input-group class="mt-1">
           <b-form-textarea v-model="body" rows="7" placeholder="Contenuto" />
        </b-input-group>
        <div class="w-25">
          <b-input-group class="mt-1">
              <b-form-input
                :class="{'border-warning': viewPwInput}"
                v-model="password"
                placeholder="Password (opzionale)"
              />
          </b-input-group>
          <b-input-group class="mt-1">
            <!-- accept=".jpg, .png, .gif" -->
            <b-form-file
              class="form-control"
              v-model="upload_file"
              :state="upload_file ? Boolean(upload_file) : null"
              placeholder="Scegli un file da allegare..."
              drop-placeholder="Trascina qui il file..."
              browse-text="Allega"
              :max-size="2 * 1024 * 1024"
            />
          </b-input-group>
          <div v-if="upload_file" class="text-success">File: {{ upload_file ? upload_file.name : '' }}</div>
        </div>
        <div>
          <b-card-text class="text-secondary m-2">
            Vuoi inviare una segnalazione anonima?
          </b-card-text>
          <b-input-group class="py-1">
            <b-form-radio class="mx-1" v-model="revealIdentity" name="identity-radios" :value="true">
              <div class="pl-1">
                Voglio firmare la mia segnalazione
              </div>
            </b-form-radio>
            <b-form-radio class="mx-1" v-model="revealIdentity" name="identity-radios" :value="false">
              <div class="pl-1">
                Voglio rimanere <strong>anonimo</strong>
              </div>
            </b-form-radio>
          </b-input-group>
          <div v-if="revealIdentity">
            <b-input-group class="my-1">
               <b-form-input v-model="name" placeholder="Nome" />
            </b-input-group>
            <b-input-group class="my-1">
               <b-form-input v-model="surname" placeholder="Cognome" />
            </b-input-group>
            <b-input-group>
               <b-form-input v-model="email" placeholder="Email" />
            </b-input-group>
          </div>
          <b-card-text class="text-secondary m-2">
            Vuoi ricevere una risposta?
          </b-card-text>
          <b-input-group class="py-1">
            <b-form-radio class="mx-1" v-model="awaitAnswer" name="answer-radios" :value="true">
              <div class="pl-1">
                Si, voglio ricevere una risposta
              </div>
            </b-form-radio>
            <b-form-radio class="mx-1" v-model="awaitAnswer" name="answer-radios" :value="false">
              <div class="pl-1">
                No, non mi aspetto una risposta
              </div>
            </b-form-radio>
          </b-input-group>
        </div>
        <b-row align-h="end">
          <b-button
            class="m-1"
            :variant="`outline-${isDark ? 'warning' : 'primary'}`"
            @click="warnErrorsHints"
          >
            Invia
          </b-button>
        </b-row>
      </b-card>
    </div>

    <b-modal
      id="pwd-warning"
      title="Non hai inserito una password"
      ok-title="Invia senza password"
      cancel-title="Scegli una password"
      @ok="submitSegnalazione()"
      @cancel="showPwdInput()"
    >
     <p class="my-4">
       Se la segnalazione contiene contenuti sensibili ti consigliamo di scegliere una password per incrementare la sicurezza.
     </p>
    </b-modal>

    <footer
      class="footer footer-light"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
  </div>
</template>

<script>
import {
  BImg, BRow, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormFile, BInputGroup, BInputGroupAppend,
  BCard, BCardText, BCardTitle, BLink, BButton, BFormTextarea, BFormRadio
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import { computed } from 'vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import { $themeConfig } from '@themeConfig'
// import SegnalazioneForm from './Segnalazione.vue'


export default {
  data() {
    return {
      choosing: true,
      appLogoImage: $themeConfig.app.appLogoImage,
      darkLogoImg: $themeConfig.app.appLogoImage,
      azienda: null,
      logoAzienda: null,
      apiLocation: null,
      logoUrl: null,
      gettingA: false,
      idSegn: null,
      password: null,
      id: null,
      title: '',
      name: null,
      surname: null,
      email: null,
      body: '',
      upload_file: null,
      dateOptions: {  timeStyle:"short", dateStyle: 'medium' },
      viewPwInput: false,
      diff: false,
      revealIdentity: false,
      awaitAnswer: false,
    }
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { isDark }
  },
  mounted() {
    this.getAzienda()
  },
  computed: {
    azToken() {
      return this.$route.params.token;
    },
  },
  methods: {
    getAzienda() {
      this.$http.get('/az/getDetails').then(res => {
        // console.log('AZIENDA ',res)
        delete res.data.subscription
        delete res.data.id
        delete res.data.createdAt
        delete res.data.updatedAt
        delete res.data.indEmailC // Never update
        delete res.data.indNumeroL
        delete res.data.indTipoViaL
        delete res.data.indNumeroC
        delete res.data.indTipoViaC
        this.azienda = res.data
      })
    },
    buildSaveObject() {
      let obj = {
        password: this.password,
        awaitAnswer: this.awaitAnswer,
        title: this.title,
        contenutos: {
          body: this.body,
        }
      }
      if (this.revealIdentity) {
        if (this.name) obj['name'] = this.name
        if (this.surname) obj['surname'] = this.surname
        if (this.email) obj['email'] = this.email
      }
      return obj
    },
    buildSaveObject_new() {
      const formData = new FormData();

      // Add form data fields
      if (this.password) {
        formData.append('password', this.password);
      }
      formData.append('awaitAnswer', this.awaitAnswer);
      formData.append('title', this.title);
      formData.append('contenutos[body]', this.body);

      if (this.upload_file) {
        formData.append('contenutos[upload_file]', this.upload_file, this.upload_file.name);
      }

      if (this.revealIdentity) {
        if (this.name) formData.append('name', this.name);
        if (this.surname) formData.append('surname', this.surname);
        if (this.email) formData.append('email', this.email);
      }

      return formData;
    },
    warnErrorsHints() {
      if (this.upload_file && this.upload_file.size > 2 * 1024 * 1024) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File troppo grande',
            text: 'Inserire un file 2MB max',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        if (!this.segnalazione && !this.password) {
            this.$bvModal.show('pwd-warning');
        } else {
          this.submitSegnalazione()
        }
      }
    },
    submitSegnalazione() {
      if (this.body && this.title && this.title.length > 5 && this.body.length > 9) {
        this.$http.post('/segnalazione/'+this.azToken+'/crea',
          this.buildSaveObject_new()
        ).then(res => {
          // console.log(res,'then post crea');
          if (res && res.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nuova Segnalazione',
                text: 'Inserita con successo',
                icon: 'SlackIcon',
                variant: 'success',
              },
            })
            this.$router.push({
              name: 'anonReportCourtesy',
              params: {
                token: this.$route.params.token,
                uuid: res.data.segn.uuid,
                password: res.data.segn.password
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Qualcosa è andato storto, riprovare',
                icon: 'AlertTriangleIcon',
                variant: 'error',
              },
            })
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Segnalazione incompleta',
            text: 'Inserire titolo e contenuto di almeno 10 caratteri',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
    getSegnalazione() {
      if (this.id && this.id.length)
        this.$router.push({
          name: 'anonReport',
          params: {
            token: this.$route.params.token,
            uuid: this.id,
            password: this.password
          }
        })
      else
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore',
            text: 'Inserire un id valido',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
    },
    getAzienda() {
      this.gettingA= false
      this.$http.get('/segnalazione/'+this.azToken).then(res => {
        this.azienda = res.data.az
        if (this.azienda.logoFile.startsWith("public/")) {
          this.logoAzienda = this.azienda.logoFile.slice(7);
        } else {
          this.logoAzienda = this.azienda.logoFile;
        }
        this.apiLocation = process.env.VUE_APP_BACKEND_API+'/'
        this.logoUrl = this.apiLocation+this.logoAzienda
        this.gettingA = true
      })
    },
    resetField() {
      this.revealIdentity = false;
      this.awaitAnswer = false;
      this.choosing = true;
      this.password = null;
      this.id = null;
      this.title = '';
      this.body = '';
      this.name = null;
      this.surname = null;
      this.email = null;
      this.upload_file = null;
    },
    showPwdInput() {
      this.viewPwInput = true;
      setInterval(() => {
        this.viewPwInput = false;
      }, 1800);
    }
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BCardText,
    BCardTitle,
    BLink,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    BCardActions,
    BImg,
    ToastificationContent,
    DarkToggler,
    BFormRadio,
    AppFooter,
  },
}
</script>

<style>

</style>
